<template>
  <div class="wrapper">
    <PageSection
      v-for="(section, index) in sections"
      :key="`${index}-section`"
      :section="section"
      :class="{ first: index === 0, 'section-wrapper--home': true }"
      render-mode="home"
    />
  </div>
</template>

<script setup lang="ts">
import { metaGen } from '~/util/metadata';
import { getFirstIndex } from '~/util/arrays';
import { useRootStore } from '~/store';

const rootStore = useRootStore();
const $router = useRouter();
const { $bus }: any = useNuxtApp();
const { locale } = useI18n();
const $cfFetch = useCfFetch();

const { data: asyncData, error: asyncError } = await useAsyncData(async () => {
  const contentStackData = await $cfFetch(`/api/contentstack/home?locale=${locale?.value}`);
  const page = getFirstIndex(contentStackData?.data?.all_home?.items);
  if (!page) throw createError({ statusCode: 404, message: 'Page not found' });

  return {
    contentStackData,
    page,
  };
});

useAsyncErrorHandling({
  error: asyncError.value,
});

const state = reactive({
  page: asyncData.value?.page || ({} as any),
});

useHead({
  ...(metaGen(rootStore?.brandSettings, {
    ...(state?.page?.seo || {}),
  }) as any),
});

const sections = computed(() => state.page?.sections_home || []);
const currentUrl = computed(() => window.location.href);
const qrCodeRedirect = () => {
  const url = currentUrl.value;
  const route = url.split('#').pop();
  if (route === 'smile-home') {
    $router.push('firestarter-rewards');
  }
};

onMounted(() => {
  qrCodeRedirect();
  $bus.emit('pageView', {
    url: document.URL,
    homepage: true,
  });
});
</script>
